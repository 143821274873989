import React, { useEffect, useState } from "react";
import { CgMenuRight } from "react-icons/cg";
import { FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavItem,
  Navigate,
  NavItemButton,
} from "./NavbarStyles";
import { navbarData } from "../../data/NavbarData";
import { useLocation } from "react-router-dom/";
import Modal2 from "../Modal/Modal2";
import useMediaQuery from "../../hook/use-media-querry";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
// import LogoNavbar from "./logohut.png";
// import Countdowntimer from '../CountdownTimer/Index';
const startDateTobk = new Date("2024-07-15").getTime();
const endDateTobk = new Date("2024-08-10").getTime();
const now = new Date().getTime();
const periodTobk = now >= startDateTobk && now <= endDateTobk;
const Navbar = ({ alert }) => {
  const isMobile = useMediaQuery("(max-width: 825px)");

  // const Countdown = Countdowntimer();
  // const Month = Countdown.month;
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const closeMobileMenu = (id) => {
    scrollTo(id);

    setShow(false);
  };

  const toggleModal2 = () => {
    if (!showModal2) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    setShowModal2(!showModal2);
  };
  // Mendapatkan pathname dari lokasi saat ini
  const currentPath = location.pathname;

  // Mengecek apakah Anda berada di halaman rumah atau tidak
  const isHome = currentPath === "/";



  // Conditional marginTop based on alert visibility
  const heroMarginTop = alert ? "-15px" : "0px";


  // Conditional style based on alert visibility and device type
  const getStyle = () => {
    const marginTop = alert ? "130px" : "0px";
    const maTop = alert ? "110px" : "0px";
    return {
      marginTop: isMobile ? maTop : marginTop,
    };
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        {/* {alert && (
          <div
            className="alert alert-warning alert-dismissible fade show text-center"
            style={{ marginBottom: mb }}
            role="alert"
          >
            {isMobile ? (
              <div className="marquee-container">
                <motion.p
                  className="marquee-text"
                  animate={{ x: ["100%", "-100%"] }}
                  transition={{
                    duration: 20,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                >
                  Dapatkan Diskon Merdeka 10% + 5% untuk pembelian produk bimbel
                  di website Ganesha Operation. Daftar sekarang, yuk!
                </motion.p>
              </div>
            ) : (
              <p className="my-auto fw-bold ">
                Dalam rangka Hari Kemerdekaan Indonesia yang ke-79, dapatkan
                diskon spesial 10% + 5% dengan pembelian produk bimbel di
                website Ganesha Operation.{" "}
                <a href="https://ganeshaoperation.com/bimbel/produk">
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "red",
                      zIndex: 999,
                      cursor: "pointer",
                    }}
                  >
                    Daftar sekarang
                  </span>
                  ,
                </a>{" "}
                yuk!
              </p>
            )}

            <button
              style={{ zIndex: 999 }}
              onClick={() => setAlert(false)}
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            />
          </div>
        )} */}
        <div style={{ marginTop: heroMarginTop }}>
          <Nav
            style={{
              backgroundColor: isHome ? "transparent" : "#f04f62",
            }}
          >
            <NavbarContainer style={getStyle()}>
              <NavLogo to="/">
                <NavIcon src={"/images/logo.png"} alt="" />
              </NavLogo>
              <MobileIcon
                style={{ marginTop: alert ? "45px" : "-10px" }}
                onClick={() => setShow(!show)}
              >
                {show ? <FaTimes /> : <CgMenuRight />}
              </MobileIcon>
              <NavMenu show={show}>
                {navbarData.map((el, index) => (
                  <NavItem key={index}>
                    <NavLinks to="/" onClick={() => closeMobileMenu(el.to)}>
                      {el.text}
                    </NavLinks>
                  </NavItem>
                ))}
                <NavItem>
                  {/* <NavLinks
                to={{
                  pathname: 'https://ganeshaoperation.com/gokreasi/',
                }}
                target="_blank"
              >
                GO Kreasi
              </NavLinks> */}
                  <NavLinks>
                    {/* <div
                  className=""
                  id="navbarNavDarkDropdown"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown"> */}
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "white",
                      }}
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Formula Belajar
                    </button>
                    <ul
                      style={{
                        backgroundColor: "#EC4357",
                      }}
                      className="dropdown-menu dropdown-menu-dark"
                    >
                      <li>
                        <a
                          onClick={() => closeMobileMenu("services")}
                          className="dropdown-item"
                          href="/"
                        >
                          3B
                        </a>
                      </li>
                      <li>
                        <button
                          onClick={() =>
                            (window.location.href =
                              "https://ganeshaoperation.com/teasergo/")
                          }
                          className="dropdown-item"
                          href="#"
                        >
                          Mengerjakan Soal
                        </button>
                      </li>
                    </ul>
                    {/* </li>
                  </ul>
                </div> */}
                  </NavLinks>
                </NavItem>

                <Navigate
                  to="/"
                  onClick={toggleModal2}
                  // style={{ textDecoration: 'none', color: 'white' }}
                >
                  Produk Kami
                </Navigate>
                <Navigate
                  // to="https://hut.ganeshaoperation.com/"
                  onClick={() =>
                    window.open("https://hut.ganeshaoperation.com/", "_blank")
                  }
                  // style={{ textDecoration: 'none', color: 'white' }}
                >
                  HUT GO
                </Navigate>
                {periodTobk && (
                  <Navigate
                    // to="https://hut.ganeshaoperation.com/"
                    onClick={() =>
                      window.open(
                        "https://registrasi.ganeshaoperation.com/",
                        "_blank"
                      )
                    }
                    // style={{ textDecoration: 'none', color: 'white' }}
                  >
                    TOBK
                  </Navigate>
                )}

                <NavItemButton
                  onClick={() =>
                    window.open(
                      "https://ganeshaoperation.com/bimbel/user/login",
                      "_blank"
                    )
                  }
                >
                  Login
                </NavItemButton>
              </NavMenu>
            </NavbarContainer>
            <Modal2 showModal={showModal2} toggleModal={toggleModal2} />
          </Nav>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
