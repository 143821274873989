import React, { useEffect, useState } from "react";
// import Countdowntimer from '../../CountdownTimer/Index';
import "./index.css";
import SI from "./uptobk.png";
const Modaldiskonagt = (props) => {
  // const Countdown = Countdowntimer(
  //   '2023-08-19T23:59:00',
  //   '2023-08-17T00:01:00'
  // );

  // const [copied, setCopied] = useState(false);

  // useEffect(() => {
  //   if (Countdown.condition === false) {
  //     setShow(false);
  //   } else setShow(true);
  // }, [Countdown.condition]);
  // console.log('show :', show);
  // console.log('kondisi :', Countdown.condition);
  // console.log('ini countdown', Countdown);

  // const buttonVariants = {
  //   initial: { scale: 1 },
  //   copied: { scale: 1 },
  //   success: { scale: 1.2 },
  // };

  // const copiedText = () => {
  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 600);
  // };

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 1000,
  //   autoplaySpeed: 3000,
  //   autoplay: true,
  //   initialSlide: 0,
  //   pauseOnHover: true,
  // };


  return (
    <>
      {/* {Countdown.condition && show && ( */}
      <>
        <div className="diskonagt-bg"></div>

        <div className="diskonagt-container">
          <div className="diskonagt-wrapper position-relative">
            <div
              data-bs-title="Default tooltip"
              onClick={props.close}
              style={{
                width: "25px",
                height: "25px",
                cursor: "pointer",
                zIndex: 99,
              }}
              className="text-center fw-bold position-absolute  start-100 translate-middle bg-warning rounded-circle"
            >
              X
            </div>
            <a href="https://registrasi.ganeshaoperation.com/" target="_blank">
              <img
                style={{ width: "100%" }}
                src={SI}
                className="rounded-3"
                alt="promo ganesha opeartion"
              />
            </a>
          </div>
        </div>
      </>
    </>
  );
};

export default Modaldiskonagt;
