import React from "react";
import useMediaQuery from "../../hook/use-media-querry";
import { motion } from "framer-motion";
const AlertDiskonTop = ({ setAlert }) => {
  const isMobile = useMediaQuery("(max-width: 825px)");
  const mb = isMobile ? "-10px" : "0px";
  return (
    <div
      className="alert alert-warning alert-dismissible fade show text-center  "
      style={{ marginBottom: mb, zIndex: 999, maxHeight: "65px" }}
      role="alert"
    >
      {isMobile ? (
        <div className="marquee-container">
          <motion.p
            className="marquee-text"
            animate={{ x: ["100%", "-100%"] }}
            transition={{
              duration: 20,
              repeat: Infinity,
              ease: "linear",
            }}
          >
            Dapatkan Diskon Merdeka 10% + 5% untuk pembelian produk bimbel di
            website Ganesha Operation. Daftar sekarang, yuk!
          </motion.p>
        </div>
      ) : (
        <p className="my-auto fw-bold ">
          Dalam rangka Hari Kemerdekaan Indonesia yang ke-79, dapatkan diskon
          spesial 10% + 5% dengan pembelian produk bimbel di website Ganesha
          Operation.{" "}
          <a href="https://ganeshaoperation.com/bimbel/produk">
            <span
              style={{
                textDecoration: "underline",
                color: "red",

                cursor: "pointer",
              }}
            >
              Daftar sekarang
            </span>
            ,
          </a>{" "}
          yuk!
        </p>
      )}

      {/* <strong>Holy guacamole!</strong> You should check in on some of
            those fields below. */}
      <button
        style={{ zIndex: 999 }}
        onClick={() => setAlert(false)}
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      />
    </div>
  );
};

export default AlertDiskonTop;
