import React, { useState, useEffect } from "react";
import Content from "../components/Content/Content";
import Cars from "../components/Products/Cars";
import Hero from "../components/Hero/Hero";
import Diskon from "../components/Diskon/Diskon";
import Profile from "../components/Profile";
import Tigab from "../components/tigab";
import Faq from "../components/faq/Faq";
import Gokre from "../components/gokre/Gokre";
import Testi from "../components/Testi/banner/index";
import Blog from "../components/Blog";
// import Navbar from "../components/Navbar/Navbar";
import Muri from "../components/Muri";
// import Modalpromo from '../components/Modal/Modalpromo';
import { heroOne } from "../data/HeroData";
import Modaltesti from "../components/Modal/Modaltestimoni";
import Modaldiskonagt from "../components/Modal/Modaldiskonagt";
// import ModalHut from "../components/Modal/Modalhut";
import "./homepage.css";

import "sweetalert2/dist/sweetalert2.min.css";

const HomePage = () => {
  const [openModal, setOpenModal] = useState(true);
  const [linkTesti, setTesti] = useState();
  const [modalYtTesti, setModalYtTesti] = useState(false);
  const ytTesti = [
    "https://www.youtube.com/embed/rT73Z1IjQd4?autoplay=1",
    "https://www.youtube.com/embed/SO_bnv_KQQ8?autoplay=1",
    "https://www.youtube.com/embed/A1bJg5eZLA0?autoplay=1",
    "https://www.youtube.com/embed/m5_-zj-ILbc?autoplay=1",
    "https://www.youtube.com/embed/0S84xtQ9-QE?autoplay=1",
    "https://www.youtube.com/embed/XWxF_FCeg08?autoplay=1",
  ];

  // Empty dependency array to ensure the effect runs only once

  // Empty dependency array to ensure the effect runs only once
  const startDateTobk = new Date("2024-07-15").getTime();
  const endDateTobk = new Date("2024-08-18").getTime();
  const now = new Date().getTime();
  const periodTobk = now >= startDateTobk && now <= endDateTobk;

  return (
    <>
      {modalYtTesti && (
        <Modaltesti close={() => setModalYtTesti(false)} src={linkTesti} />
      )}
      {/* <Navbar isHome={true} /> */}
      {openModal && periodTobk && (
        <Modaldiskonagt close={() => setOpenModal(false)} />
      )}
 
      <Hero />
      <Profile />
      <Muri />
      <Blog />
      <Cars />
      <Diskon />
      <Content {...heroOne} />
      <Tigab />
      <Testi
        openmodal={() => setModalYtTesti(true)}
        pangestu={() => setTesti(ytTesti[0])}
        putriani={() => setTesti(ytTesti[1])}
        gemi={() => setTesti(ytTesti[2])}
        nabila={() => setTesti(ytTesti[3])}
        amirul_rayyis={() => setTesti(ytTesti[4])}
        mfarouq={() => setTesti(ytTesti[5])}
      />
      <Faq />
      <Gokre />
    </>
  );
};

export default HomePage;
